import { useState } from "react";
import { VStack, Button, Text, HStack, Select, Box } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";


export default function Web3Provider({ disconnect, connectWallet, switchNetwork, handleNetwork, isLoading, account, webTheme, error, truncateAddress, buttonText }) {

    return (
        <>
            <VStack justifyContent="center" alignItems="center">

                {isLoading && <div>Loading...</div>}
                <HStack>
                    {!account ? (
                        <Button onClick={connectWallet}>Connect Wallet</Button>
                    ) : (
                        <div>
                            <Text style={{ color: webTheme ? 'white' : 'black' }}>{`Wallet: ${truncateAddress(account)}`}</Text>
                            <Button onClick={disconnect}>Disconnect</Button>
                        </div>
                    )}
                </HStack>
                <VStack justifyContent="center" alignItems="center" padding="10px 0">

                    {/* <Tooltip label={account} placement="right">
                        <Text>{`Account: ${truncateAddress(account)}`}</Text>
                    </Tooltip> */}
                </VStack>


            </VStack>
        </>
    );
}
