import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showModal } from './modalSlice';
import { deletePostService, doBanService, verifyUserService } from '../services/adminService';
import { deleteAllUserPosts, deletePost as deletePostSlice } from './postSlice';
import { deleteNews as deleteNewsSlice } from './newsSlice';
import { banUser, setVerifyForUser } from './usersSlice';
import { deleteNewsService } from '../services/newsService';

const initialState = {
  banType: null,
  banTypeId: null
};

export const doBan = createAsyncThunk("admin/ban", async (props, thunkAPI) => {
  const { customFetch, banTypeId, banType, reason } = props;
  const { dispatch, rejectWithValue } = thunkAPI;

  const data = await customFetch(doBanService, { banTypeId, banType, reason });
  if (!data) return rejectWithValue();
  dispatch(showModal({ msg: "Banned!" }));
  switch (banType) {
    case "post":
      dispatch(deletePostSlice({ id: banTypeId, frontOnly: true }));
      break;
    case "user":
      dispatch(banUser({ id: banTypeId }));
      dispatch(deleteAllUserPosts({ id: banTypeId }))
      break;
    default:
      break;
  }
});

export const verifyUser = createAsyncThunk("admin/verify", async (props, thunkAPI) => {
  const { customFetch, id } = props;
  const { dispatch, rejectWithValue } = thunkAPI;

  const data = await customFetch(verifyUserService, { id });
  if (!data) return rejectWithValue();
  dispatch(showModal({ msg: "User verified!" }));
  dispatch(setVerifyForUser({ id }));
});

export const deletePost = createAsyncThunk("admin/post-delete", async (props, thunkAPI) => {
  const { customFetch, id } = props;
  const { dispatch, rejectWithValue } = thunkAPI;

  const data = await customFetch(deletePostService, { id });
  if (!data) return rejectWithValue();
  dispatch(deletePostSlice({ id, frontOnly: true }));
});
export const deleteNews = createAsyncThunk("admin/post-delete", async (props, thunkAPI) => {
  const { customFetch, id } = props;
  const { dispatch, rejectWithValue } = thunkAPI;

  const data = await customFetch(deleteNewsService, { id });
  if (!data) return rejectWithValue();
  dispatch(deleteNewsSlice({ id, frontOnly: true }));
});

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setToBan: (state, action) => {
      const { type, id } = action.payload;
      state.banType = type;
      state.banTypeId = id;
    },
    resetBanData: (state) => {
      state.banType = null;
      state.banTypeId = null;
    }
  },
});

export const { setToBan, resetBanData } = adminSlice.actions;
export default adminSlice.reducer;
