import React, { useEffect, useState } from "react";
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Box,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import Buttons from "../../components/Button/Button";
import CryptoJS from "crypto-js";
import "@chakra-ui/react";
import {
	dp,
	closeIcon,
	searchIcon,
	hamburger,
	chatIcon,
	bellIcon,

} from "../../assets";
import LightHamb from '../../assets/menu-icon-image.png';
import BluePlus from '../../assets/pluseblue.png';
import OrangePlus from '../../assets/plusorange.png';
import LightClose from '../../assets/close-black.png';
import lightNotifiaction from '../../assets/notifications-light.png'
import lightUser from '../../assets/userIcon-light.png'
import closeBlack from '../../assets/close-black.png'
import searchBlack from '../../assets/search-black.png'
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, toggleSidebar } from "../../features/modalSlice";
import useFetch from "../../hooks/useFetch";
import SearchResults from "../SearchResults/SearchResults";
import { fetchUsersService } from "../../services/userServices";
import { fetchPostsService } from "../../services/postServices";
import { logout } from "../../features/userSlice";
import { setSearchQuery, setDoSearch, setThemeHandle } from "../../features/searchSlice";
import Notifications from "../Notifications/Notifications";
import "./appbar.css";
import CreatePost from "../CreatePost/CreatePost";
import { setAllPosts, setAllPostsType } from "../../features/postSlice";
import { EventEmitter } from "@solana/wallet-adapter-base";
import CreateNews from "../CreateNews/CreateNews";
import lightIcon from '../../assets/day-mode.png'
import darkIcon from '../../assets/night-mode.png'
import darkIogo from '../../assets/onlightlogo.png'
import homeIcon from '../../assets/ondarklogo.png'
import DrawerMenu from "../drawer/DrawerMenu";
import Sidebar from "../sidebar/Sidebar";

const Appbar = () => {
	// global states
	const location = useLocation();
	const param = useParams();
	let userId = param?.id;

	const adminWallet = ["668241e3464d561c43c5182f", '66a75ebed0a2974d92aa0e1c', '66e82f6f90648bdc9b7e1b98', '66dbeb93622895e0860d6da7', '66decc5907d1ae43999297fb', '66e9667d70630f7cc21d3bb5'];

	const pathname = location?.pathname;
	const {
		user: { id, profileImage, isGuest },
		modal: { isSidebarVisible },
		search: { searchQuery: query, doSearch, webTheme, yellowVisible },
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const customFetch = useFetch();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [showNotifications, setShowNotifications] = useState(false);
	const [searchResult, setSearchResult] = useState({});
	const [createPostFlag, setCreatePostFlag] = useState("");
	const [account, setAccount] = useState("");
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);

	const [isDarkTheme, setIsDarkTheme] = useState(() => {
		const storedTheme = localStorage.getItem("theme");
		return storedTheme ? JSON.parse(storedTheme) : true;
	});

	const {
		post: {
			allPosts: { posts, page, isLoading, type },
		},
	} = useSelector((state) => state);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	console.log(isMobile, 'isMobileisMobileisMobile');

	const ENCRYPTION_KEY = "info-solebook-admin";

	const resetInfinityScroll = new EventEmitter();

	const decrypt = (cipherText) => {
		const bytes = CryptoJS.AES.decrypt(cipherText, ENCRYPTION_KEY);
		return bytes.toString(CryptoJS.enc.Utf8);
	};

	const getWalletAddress = () => {
		const encryptedAddress = localStorage.getItem("walletAddress");
		if (encryptedAddress) {
			return decrypt(encryptedAddress);
		}
		return null;
	};

	useEffect(() => {
		const savedAddress = getWalletAddress();
		if (savedAddress) {
			setAccount(savedAddress);
		}
	}, []);

	useEffect(() => {
		// Update local storage when theme changes
		localStorage.setItem("theme", JSON.stringify(isDarkTheme));
	}, [isDarkTheme]);

	useEffect(() => {
		// Update local storage and Redux state when theme changes
		dispatch(setThemeHandle(isDarkTheme));
	}, [isDarkTheme, dispatch]);

	useEffect(() => {
		if (doSearch) searchHandler();
	}, [doSearch, dispatch]);

	const searchHandler = async (e = null) => {
		dispatch(setDoSearch(false));
		e?.preventDefault();
		if (query.length > 0) {
			dispatch(setIsLoading(true));
			const { posts } = await customFetch(fetchPostsService, { query, type: "all" });
			const { users } = await customFetch(fetchUsersService, { query });
			setSearchResult({ posts, users });
			dispatch(setIsLoading(false));
		}
	};

	const reset = () => {
		dispatch(setSearchQuery(""));
		dispatch(setDoSearch(false));
		setSearchResult({});
	};

	const gotoLogin = () => dispatch(logout());

	const handleSearchInput = (e) => {
		dispatch(setSearchQuery(e.target.value));
	};

	const buttons = ["all", "followed"];
	const triggerPostType = async (postType) => {
		if (postType === type) return;
		dispatch(setAllPostsType(postType));
		resetInfinityScroll.emit("doReset");
		const data = await customFetch(fetchPostsService, {
			page: 1,
			type: postType,
			userId: id,
		});
		dispatch(setAllPosts({ posts: data.posts, page: data.page }));
	};

	const OpenCreatePost = () => {
		onOpen();
		setCreatePostFlag("post");
	};

	const OpenCreateNews = () => {
		onOpen();
		setCreatePostFlag("news");
	};

	const closeFunction = () => {
		onClose();
		setCreatePostFlag("");
	};

	const toggleTheme = () => {
		const newTheme = !isDarkTheme;
		setIsDarkTheme(newTheme);
	};
	const closIcon = webTheme ? closeIcon : LightClose

	const toggleDrawer = () => {
		setIsOpenDrawer((prevState) => !prevState);
	};

	return (
		// <header className={searchResult.posts || searchResult.users ? "appbar topZ" : "appbardark"}>
		<>
			{isMobile && !isOpenDrawer && <header className={webTheme ? "appbardark" : "appbarlight"} style={{ zIndex: '0000' }}>

				<Link to="/">
					<img style={{ width: '45px', height: '38px' }} src={webTheme ? homeIcon : darkIogo} alt="home" />
				</Link>

			</header>}
			<header style={{
				top: yellowVisible ? '3rem' : '0', width: isMobile ? "100%" : "100%",

			}} className={webTheme ? "appbardark" : "appbarlight"}>

				{/* <div className={webTheme ? "hamburger_dark" : "hamburger_light"} onClick={() => dispatch(toggleSidebar(!isSidebarVisible))} style={{ height: !webTheme && "40px" }}> */}
				<div className={webTheme ? "hamburger_dark" : "hamburger_light"} onClick={() => toggleDrawer()} style={{ height: !webTheme && "40px" }}>
					<img src={isSidebarVisible ? closIcon : webTheme ? hamburger : LightHamb} alt="hamburger" />
				</div>
				<DrawerMenu
					setIsOpen={setIsOpenDrawer}
					isOpen={isOpenDrawer}
					toggleDrawer={toggleDrawer}
				/>

				<Link to="/">
					<img src={webTheme ? homeIcon : darkIogo} alt="home" className="home-icon" />
				</Link>

				<form onSubmit={searchHandler} className="searchform">
					<button className="serchIcon_class" type="submit" aria-label="search">
						<img style={{ width: isMobile ? '8pcx' : '13px', height: isMobile ? '8px' : '13px' }} src={webTheme ? searchIcon : searchBlack} alt="search" />
					</button>
					<input
						type="text"
						placeholder="Search..."
						value={query}
						onChange={handleSearchInput}
					/>
					<button className={webTheme ? "btn_close_bar dark" : "btn_close_bar light"} onClick={reset} type="button" aria-label="clear search">
						<img style={{
							width: webTheme ? '7px' : '13px',
							height: webTheme ? '7px' : '13px',
						}} src={webTheme ? closeIcon : closeBlack} alt="close" className="close" />
					</button>
					{(searchResult.posts || searchResult.users) && (
						<SearchResults searchResult={searchResult} reset={reset} />
					)}
				</form>
				{!isGuest && (
					<div className={isMobile ? "" : "btn_create_post"} onClick={OpenCreatePost}>
						{isMobile ? <img style={{ height: '23px', width: '23px' }} src={webTheme ? OrangePlus : BluePlus} alt="theme-toggle" /> : <button>New Post</button>}
					</div>
				)
				}
				{
					!isGuest && pathname.includes("user") && adminWallet.includes(id) && (
						<div className="btn_create_news" onClick={OpenCreateNews}>
							<button>Create News</button>
						</div>
					)
				}
				<>
					{isOpen && <Box className="grey-overlay" onClick={closeFunction}></Box>}
					{isOpen && createPostFlag === "post" && (
						<Modal isOpen={isOpen} onClose={closeFunction} isCentered>
							<ModalOverlay />
							<ModalContent
								maxW="600px"
								minH="300px"
								maxH="auto"
								mx="auto"
								border={webTheme ? "1px solid #3c3e44" : "1px solid #ececec"}
								borderRadius="20px"
								backgroundColor={webTheme ? "#1d1d1d" : "#f7f7f7 "}
								boxShadow="xl"
								p="4"
							>
								<ModalHeader
									// bg="#f7f7f7"
									color="white"
									borderTopRadius="lg"
									marginTop={"10px"}
									marginLeft={"10px"}
									alignItems={"end"}
								>
									<ModalCloseButton color={webTheme ? "white" : "blue"} />
								</ModalHeader>
								<ModalBody>
									<Box p="4" bg="gray.100" borderRadius="md">
										<Text fontSize="lg">
											{isGuest || (
												<div className={webTheme ? "createChat_main_dark" : "createChat_main_light"}>
													<CreatePost />
													{/* {!isGuest && (
												<div className="btn-group create_chat_btn">
													{buttons?.map((title) => (
														<Buttons
															title={title}
															isActive={type === title}
															backgroundColor={webTheme ? "#1b1919" : "#D0D0D0"}
															key={title}
															onClick={() => triggerPostType(title)}
															disabled={isLoading}
														></Buttons>
													))}
												</div>
											)} */}
												</div>
											)}
										</Text>
									</Box>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
					{isOpen && createPostFlag === "news" && (
						<Modal isOpen={isOpen} onClose={closeFunction} isCentered>
							<ModalOverlay />
							<ModalContent
								maxW="600px"
								minH="300px"
								maxH="auto"
								mx="auto"
								border={webTheme ? "1px solid #3c3e44" : "1px solid #ececec"}
								borderRadius="20px"
								backgroundColor={webTheme ? "#1d1d1d" : "#f7f7f7 "}
								boxShadow="xl"
								p="4"
							>
								<ModalHeader
									bg="blue.500"
									color="white"
									borderTopRadius="lg"
									marginTop={"10px"}
									marginLeft={"10px"}
									alignItems={"end"}
								>
									<ModalCloseButton color={webTheme ? "white" : "blue"} />
								</ModalHeader>
								<ModalBody>
									<Box p="4" bg="gray.100" borderRadius="md">
										<Text fontSize="lg">
											{isGuest || (
												<div className="createChat_main">
													<CreateNews />
												</div>
											)}
										</Text>
									</Box>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
				</>
				<nav className="appbar__profile">
					{isGuest ? (
						<button className="login-btn" onClick={gotoLogin}>
							Login
						</button>
					) : (
						<>
							<Link to={`/user/${id}`}>
								<img
									src={webTheme ? profileImage || dp : profileImage || lightUser}
									alt="profileImage"
									className="appbar__profile__dp"
									title="profile"
								/>
							</Link>
							<div onClick={() => setShowNotifications(!showNotifications)} className="bell">
								<img className={webTheme ? "notifications_dark_img" : "notifications_light_img"} src={webTheme ? bellIcon : lightNotifiaction} alt="bell" />
								{showNotifications && (
									<div className={webTheme ? "notifications_dark" : "notifications_light"}>
										<Notifications />
									</div>
								)}
							</div>

						</>
					)}
				</nav>
				{!isMobile &&
					<div className="theme-toggle" onClick={toggleTheme} style={{ cursor: 'pointer' }}>
						<img className="theme_class_dark_light" src={isDarkTheme ? lightIcon : darkIcon} alt="theme-toggle" />
					</div>
				}

			</header >

		</>

	);
};

export default Appbar;
