import React, { Suspense, lazy, useState, useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../components/Appbar/Appbar";
import Sidebar from "../components/sidebar/Sidebar";
import Backdrop from "../components/Backdrop/Backdrop";
import EditPost from "../components/EditPost/EditPost";
import ReportPost from "../components/ReportPost/ReportPost";
import Ban from "../components/Ban/Ban";
import ProgressBar from "react-topbar-progress-indicator";
import ProtectedRoute from "./ProtectedRoute";
import { setEditingPost, setReportingPost } from "../features/postSlice";
import { resetBanData } from "../features/adminSlice";
import Terms from "../pages/Terms/Terms";
import Privacy from "../pages/Privacy/Privacy";
import Cookies from "../pages/Cookies/Cookies";
import PostDetailPage from "../pages/postDetail/PostDetail";

const Home = lazy(() => import("../pages/Home/Home"));
const SinglePost = lazy(() => import("../pages/Singlepost/SinglePost"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const Chat = lazy(() => import("../pages/Chat/Chat"));
const MessengerPage = lazy(() => import("../pages/Messenger/Messenger"));
const NotFound = lazy(() => import("../pages/NotFound/NotFound"));

const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 768);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return isMobile;
};

const Layout = () => {
	const {
		admin: { banTypeId, banType },
		post: { editingPost, reportingPost },
	} = useSelector((state) => state);

	const dispatch = useDispatch();
	const isMobile = useIsMobile();

	const closeEditing = () => {
		dispatch(setEditingPost({}));
	};

	const closeReporting = () => {
		dispatch(setReportingPost({}));
	};

	const closeBanModal = () => {
		dispatch(resetBanData());
	};
	const {

		search: { webTheme, yellowVisible, sideBarState },
	} = useSelector((state) => state);
	// const [expand, setExpand] = useState(localStorage.getItem('expand') || 'false');

	// useEffect(() => {
	// 	const handleStorageChange = () => {
	// 		setExpand(localStorage.getItem('expand') || 'false');
	// 	};

	// 	// Listen for storage changes
	// 	window.addEventListener('storage', handleStorageChange);

	// 	// Clean up the event listener
	// 	return () => {
	// 		window.removeEventListener('storage', handleStorageChange);
	// 	};
	// }, []);
	// .paddin-left {
	// 	padding-left: 180px;
	// 	padding-right: 15px;
	// }
	// .padding-left-less .paddin-left {
	// 	padding-left: 0;
	// }
	// console.log(expand, 'asfcewgvergvregv')
	return (
		<>
			<Appbar style={{ width: "100%", position: "fixed", top: 0, zIndex: 1000 }} />
			<div style={{ minHeight: "100vh", paddingTop: isMobile ? "" : "0px" }}>
				{!isMobile && (
					<div style={{ width: sideBarState && !isMobile ? "250px" : !sideBarState && !isMobile ? "70px" : '', position: "relative" }}>
						<Sidebar />
					</div>
				)}
				<div className={!isMobile && 'paddin-left'}>
					{/* <div style={{ flexGrow: 1, paddingLeft: isMobile ? "0" : "20px", paddingTop: isMobile ? "0" : "20px" }}> */}
					<Backdrop show={!!editingPost._id} onClose={closeEditing}>
						<EditPost close={closeEditing} />
					</Backdrop>

					<Backdrop show={!!reportingPost._id} onClose={closeReporting}>
						<ReportPost close={closeReporting} />
					</Backdrop>

					<Backdrop show={!!banTypeId && !!banType} onClose={closeBanModal}>
						<Ban close={closeBanModal} />
					</Backdrop>

					<Suspense fallback={<ProgressBar />}>
						<Outlet />
					</Suspense>
				</div>
			</div>
		</>

	);
};

const Router = () => {
	const authenticate = (Comp) => (
		<ProtectedRoute>
			<Comp />
		</ProtectedRoute>
	);

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route path="/terms" element={<Terms />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/cookies" element={<Cookies />} />
				<Route index element={<Home />} />
				<Route path="/post/:typeval/:id" element={<SinglePost />} />
				<Route path="/user/:id" element={<Profile />} />
				<Route path="/post/detail/:typeval/:index" element={<PostDetailPage />} />
				<Route path="/chat" element={authenticate(Chat)} />
				<Route path="/chat/messenger" element={authenticate(MessengerPage)} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

export default Router;
