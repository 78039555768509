import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchQuery: "",
  doSearch: false,
  webTheme: true,
  yellowVisible: true,
  sideBarState: true
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setDoSearch: (state, action) => {
      state.doSearch = action.payload;
    },
    setThemeHandle: (state, action) => {
      state.webTheme = action.payload;
    },
    setYellowBarHandle: (state, action) => {
      state.yellowVisible = action.payload;
    },
    sidebarStateHandle: (state, action) => {
      state.sideBarState = action.payload;
    }
  },
});

export const { setSearchQuery, setDoSearch, setThemeHandle, setYellowBarHandle, sidebarStateHandle } = searchSlice.actions;
export default searchSlice.reducer;
