import React, { useState } from "react";
import { useEffect } from "react";
import { optionsIcon } from "../../assets";
import LightOption from '../../assets/threedot.png'

import "./options.css";
import { useSelector } from "react-redux";

const Options = ({ options, id = "", isOwnPost, isAdmin, targetUser, isShowingAll }) => {
	const [isOptionsVisible, setIsOptionsVisible] = useState(false);
	const ownActions = ['Edit', 'Delete'];
	const adminActions = ['Ban', 'Verify', 'Remove'];
	const publicActions = ['Report'];
	console.log(options, 'optionsoptionsoptions')

	useEffect(() => {
		const handleOutsideClick = e => {
			if (e.target.id !== "options" + id && e.target.id !== "options__icon" + id) {
				setIsOptionsVisible(false);
			}
		};
		document.body.addEventListener("click", handleOutsideClick);
		return () => document.body.removeEventListener("click", handleOutsideClick);
	}, [id]);

	const handleClick = handler => {
		setIsOptionsVisible(false);
		handler();
	};

	const isVisibleOption = (title) => {
		const isAdminBanRule = adminActions.includes(title) && isAdmin && !targetUser?.isAdmin && !targetUser?.banned;
		return publicActions.includes(title)
			|| (isOwnPost && ownActions.includes(title) || isAdminBanRule)
			|| isShowingAll;
	}
	const {

		search: { webTheme },
	} = useSelector((state) => state);
	return (
		<div className={webTheme ? "options_dark" : "options_light"} id={"options" + id} onClick={() => setIsOptionsVisible(val => !val)} style={{ height: !webTheme && "30px" }}>
			<img src={webTheme ? optionsIcon : LightOption} alt="options" className="options__icon" id={"options__icon" + id} />
			<ul className={isOptionsVisible ? "show" : ""}>
				{Object.entries(options).map(([title, handler], i) => {
					if (isVisibleOption(title)) {
						return (
							<li onClick={() => handleClick(handler)} key={i}>
								{title}
							</li>
						);
					}
				})}
			</ul>
		</div>
	);
};

export default Options;
