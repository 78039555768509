import React, { useState } from "react";
import { closeIcon } from "../../assets";
import './imageViewer.css';

const ImageViewer = ({ data, onClose }) => {
  const { images, index } = data;
  const [currentIndex, setCurrentIndex] = useState(index);

  const decreaseIndex = () => {
    if (!currentIndex) return;
    const nextIndex = currentIndex - 1;
    setCurrentIndex(nextIndex);
  };

  const increaseIndex = () => {
    if (currentIndex === images.length - 1) return;
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

	return (
    <>
    {
      (images && index !== undefined) && (
        <div className="imageViewer">
          <button onClick={onClose} aria-label="close">
            <img src={closeIcon} alt="close"/>
          </button>
          <img src={images[currentIndex]} alt="post"/>

          {currentIndex > 0 && (
            <div className="arrow arrow__left" onClick={decreaseIndex}></div>
          )}

          {currentIndex !== images.length - 1 && (
            <div className="arrow arrow__right" onClick={increaseIndex}></div>
          )}
        </div>
      )
    }
    </>
  )
};

export default ImageViewer;
