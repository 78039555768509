import React, { useState } from "react";
import { useSelector } from "react-redux"; // Make sure to import useSelector
import Login from "../../components/Login/Login";
import Register from "../../components/Register/Register";
import "./auth.css";

const Auth = () => {
   const [isRegistering, setIsRegistering] = useState(false);

   const {
      search: { webTheme },
   } = useSelector((state) => state);

   // Determine the base class with theme
   const themeClass = webTheme === true ? "auth_dark" : "auth_light";

   // Determine the registration state class
   const registrationClass = isRegistering ? "signup" : "login";

   // Combine the theme class and registration state class
   const className = `${registrationClass} ${themeClass}`;

   return (
      <section className={className}>
         <Login setIsRegistering={setIsRegistering} isActive={!isRegistering} />
         <Register setIsRegistering={setIsRegistering} isActive={isRegistering} />
      </section>
   );
};

export default Auth;
