import React from 'react';
import './YellowBar.css';
import { useDispatch, useSelector } from 'react-redux';
import { setYellowBarHandle } from '../../features/searchSlice';
const YellowBar = ({ isVisible, setIsVisible }) => {
  const {

    search: { webTheme, yellowVisible },
  } = useSelector((state) => state);
  console.log(yellowVisible, 'yellowVisibleyellowVisible');
  const themeClass = webTheme ? 'yellow-bar-dark' : 'yellow-bar-light'
  if (!yellowVisible) return null;
  const dispatch = useDispatch();




  return (
    <div className={themeClass}>
      <p>GET $HUB ON UNISWAP <a href="/uniswap" target="_blank" rel="noopener noreferrer">HERE</a></p>
      <button onClick={() => dispatch(setYellowBarHandle(false))}>X</button>
    </div>
  );
};

export default YellowBar;
