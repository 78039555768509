import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import './reportpost.css'
import { reportPost } from '../../features/postSlice';
import useFetch from '../../hooks/useFetch';
import Reason from '../Reason/Reason';

const ReportPost = ({ close }) => {
  const {
    post: { reportingPost },
  } = useSelector(state => state);
  const placeholder = "What type of issue are you reporting?";
  const dispatch = useDispatch();
  const customFetch = useFetch();


  const submitHandler = (form) => {
    const { reason } = form;
    dispatch(reportPost({ customFetch, reason, postId: reportingPost._id }));
  }

  return (
    <>
      <Reason onSubmit={submitHandler} onClose={close} placeholder={placeholder}></Reason>
    </>
  );
};

export default ReportPost;
