import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  VStack,
  Text,
  Spacer,
} from "@chakra-ui/react";
import {
  FiHome,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setThemeHandle, sidebarStateHandle } from "../../features/searchSlice";
import { Link } from "react-router-dom"; // Import Link for navigation

const Sidebar = () => {
  const {
    user: { id, isGuest }, // Assuming `isGuest` indicates guest mode
    search: { webTheme, yellowVisible, sideBarState },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? JSON.parse(storedTheme) : true;
  });

  useEffect(() => {
    dispatch(setThemeHandle(isDarkTheme));
  }, [isDarkTheme, dispatch]);

  const bg = webTheme ? "black" : "white";
  const color = webTheme ? "white" : "black";
  const iconColor = webTheme ? "white" : "black";

  const handleToggle = () => {
    setIsExpanded(!sideBarState);
    dispatch(sidebarStateHandle(!sideBarState));
  };

  const toggleTheme = () => {
    const newTheme = !isDarkTheme;
    setIsDarkTheme(newTheme);
    localStorage.setItem("theme", JSON.stringify(newTheme));
  };

  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth < 768);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
  };

  const isMobile = useIsMobile();

  return (
    <Flex className={yellowVisible ? "sidebar_main_div" : "sidebar_main_div_hide"}>
      <Box
        bg={bg}
        color={color}
        w={isExpanded ? "250px" : "60px"}
        h="100vh"
        p={isExpanded ? "5" : "2"}
        borderRight="1px solid"
        borderColor={webTheme ? "#3c3e44" : "#D0D0D0"}
        transition="width 0.3s"
      >
        <Flex justifyContent={isExpanded ? "space-between" : "center"}>
          {isExpanded && <Text fontSize="xl"></Text>}
          <IconButton
            aria-label="Toggle Sidebar"
            icon={isExpanded ? <FiArrowLeft /> : <FiArrowRight />}
            onClick={handleToggle}
            variant="ghost"
            color={iconColor}
            className={webTheme ? "darkcross" : "lightcross"}
          />
        </Flex>

        <VStack spacing={4} mt={isExpanded ? 10 : 4} alignItems="flex-start" h="100%">
          <Flex alignItems="center">
            <Link to="/"> {/* Navigate to the home route */}
              <Flex alignItems="center">
                <FiHome color={iconColor} />
                {isExpanded && <Text ml={4}>Home</Text>}
              </Flex>
            </Link>
          </Flex>

          {/* Only show Profile if user is not a guest and id is available */}
          {!isGuest && id && (
            <Flex alignItems="center">
              <Link to={`/user/${id}`}> {/* Navigate to the profile page */}
                <Flex alignItems="center">
                  <FiUser color={iconColor} />
                  {isExpanded && <Text ml={4}>Profile</Text>}
                </Flex>
              </Link>
            </Flex>
          )}

          <Spacer />
        </VStack>
      </Box>
    </Flex>
  );
};

export default Sidebar;
