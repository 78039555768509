import { useDispatch, useSelector } from 'react-redux';
import { setDoSearch, setSearchQuery } from '../../features/searchSlice';

const URL_REGEXP = /\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/ig;
const BROKEN_URL_REGEXP = /^https?:$/;
const USER_TAG_REGEXP = /^@\S.*/;
const CUSTOM_TAG_REGEXP = /^#\S.*/;

const TextStrategy = ({ text, index, users }) => {
  const dispatch = useDispatch();

  const defineTextCase = (text, index, users) => {
    if (text.match(URL_REGEXP)) {
      return linkCase(text, index);
    }

    if (USER_TAG_REGEXP.test(text)) {
      return userTagCase(text, index, users);
    }

    if (CUSTOM_TAG_REGEXP.test(text)) {
      return customTagCase(text, index);
    }

    if (text.match(BROKEN_URL_REGEXP)) {
      return brokenLinksCase();
    }

    return regularTextCase(text);
  }

  const regularTextCase = (text) => {
    return text + ' ';
  }

  const {

    search: { webTheme },
  } = useSelector((state) => state);
  const linkCase = (text, index) => {
    return (
      <a
        href={text}
        key={index}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: webTheme ? "#FF7324" : "#666666" }}
      >
        {text}
      </a>
    )
  }

  const brokenLinksCase = () => {
    return null;
  }

  const userTagCase = (text, index, users) => {
    const username = text.slice(1);
    const userId = users.find(user => user.name === username)?._id;
    const link = `/user/${userId}`;

    return (
      <a
        href={link}
        key={index}
        rel="noopener noreferrer"
        style={{ color: "#00BFFF", display: "inline-block", marginRight: "5px" }}
      >
        {text}
      </a>
    )
  }

  const customTagCase = (text, index) => {
    return (
      <span
        onClick={() => { handleClickOnCustomTag(text) }}
        key={index}
        rel="noopener noreferrer"
        style={{ color: "#00BFFF", display: "inline-block", cursor: "pointer", marginRight: "5px" }}
      >
        {text}
      </span>
    )
  }

  const handleClickOnCustomTag = (text) => {
    dispatch(setSearchQuery(text));
    dispatch(setDoSearch(true));
  }

  return defineTextCase(text, index, users);
}

export default TextStrategy;
