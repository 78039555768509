import { useEffect, useRef, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { useConnection } from "@solana/wallet-adapter-react";
import { getAllDomains, NameRegistryState, reverseLookupBatch } from "@bonfida/spl-name-service";
import { updateDomainService } from "../services/userServices";

export const useUserDomain = (userAddress, domain) => {
    const { connection } = useConnection();
    const [result, setResult] = useState(undefined);

    const formatWalletAddress = (address) => {
        if (!address) return '';
        const start = address.slice(0, 5);
        const end = address.slice(-4);
        return `${start}...${end}`;
    };

    useEffect(() => {
        const fn = async () => {
            if (!userAddress || !connection) return;
            const storageKey = "user-domain " + userAddress;//userAddress;
            let userDomainStr = window.localStorage.getItem(storageKey);
            let waitCounter = 0;
            while (userDomainStr === "calculating" && waitCounter < 3) {
                await new Promise(resolve => setTimeout(resolve, 1000 * (1 + Math.random())));
                userDomainStr = window.localStorage.getItem(storageKey);
                waitCounter++;
            }
            let userDomain = (userDomainStr && userDomainStr !== "calculating") ? JSON.parse(userDomainStr) : undefined;
            if (userDomain && (userDomain.lastUpdated > Date.now() - (userDomain.hasDomain ? 1000 * 60 : 1000 * 10))) {
                setResult(userDomain.domain);
                return;
            }
            window.localStorage.setItem(storageKey, "calculating");
            const user = new PublicKey(userAddress);
            const domains = await getAllDomains(connection, user);
            if (domains.length > 0) {
                const reverses = await reverseLookupBatch(connection, [...domains]);
                // TODO: need to find the best one
                userDomain = {
                    domain: reverses[0] + ".sol",
                    lastUpdated: Date.now(),
                    hasDomain: true
                };
                setResult(userDomain.domain);
            }
            else {
                userDomain = {
                    domain: formatWalletAddress(userAddress),
                    lastUpdated: Date.now(),
                    hasDomain: false
                };
                setResult(userDomain.domain);
            }
            window.localStorage.setItem(storageKey, JSON.stringify(userDomain));
            if (userDomain.hasDomain && userDomain.domain != domain) {
                console.log("Update User Domain:", userDomain);
                updateDomainService({ 'domain': userDomain.domain });
            }
        };

        fn().catch(console.error);
    }, [userAddress, connection]);

    return result;
};