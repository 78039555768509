import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getTimeStringWithDiff } from "../../utils/getDateString";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { Input, useDisclosure } from "@chakra-ui/react";
import useConfirmation from "../../components/Confirmation/useConfirmation";
import Backdrop from "../../components/Backdrop/Backdrop";
import Options from "../../components/Options/Options";
import { AiFillHeart, AiOutlineHeart, AiOutlineRetweet } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import { IoIosShareAlt } from "react-icons/io";
import Share from "../../components/Post/Share";
import { dp } from "../../assets";
import verifiedUsers from "../../utils/verifiedUsers";
import { useUserDomain } from "../../hooks/useUserDomain";
import TextStrategy from "../../components/TextStrategy/TextStrategy";
import { commentPost, deletePost, likePost, repostPost, setEditingPost, setReportingPost, setSinglePost } from "../../features/postSlice";
import { deleteNews } from "../../features/newsSlice";
import { setToBan } from "../../features/adminSlice";
import "../../components/Post/post.css";
import ImageViewer from "../../components/ImageViewer/ImageViewer";
import { fetchPostsService } from "../../services/postServices";
import { useEffect } from "react";
import { fetchNewsService } from "../../services/newsService";


const PostDetailPage = () => {
    const location = useLocation();
    // const { typeval } = location.state || {};
    const { singlepost } = location.state || {};
    // console.log(post, 'postpostpostpost')
    // const post = useSelector(state => state.post?.singlePost);
    const [post, setPost] = useState();

    const createdAt = getTimeStringWithDiff(post?.createdAt);
    const [showShare, setShowShare] = useState(false);
    const [showImage, setShowImage] = useState(false);

    const [viewData, setViewData] = useState(null);
    const { typeval, index } = useParams();

    const dispatch = useDispatch();
    const customFetch = useFetch();
    const navigate = useNavigate();
    console.log(typeval, 'typevaltypevaltypeval')

    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        user: { id },
        users: { usersOnline, users },
        post: { allPosts: { isLoading } },
    } = useSelector((state) => state);
    const isOwnPost = id === post?.createdBy;
    const isLiked = post?.likes?.includes(id);
    const isReposted = post?.repostedBy?.includes(id);
    const isOnline = usersOnline.some((user) => user.id === post?.createdBy);
    const author = users.find(user => user._id === post?.createdBy);
    const isAdmin = users.find(user => user._id === id)?.isAdmin;

    const likeHandler = () => {
        dispatch(likePost({ customFetch, id: post?._id, isLiked }));
    };

    const repostHandler = () => {
        if (!isLoading) {
            dispatch(repostPost({ customFetch, postId: post?._id, isReposted }));
        }
    };

    const reportHandler = () => {
        dispatch(setReportingPost(post));
    };

    const commentHandler = (comment) => {
        dispatch(commentPost({ customFetch, id: post?._id, comment }));
    };

    const editHandler = () => {
        dispatch(setEditingPost(post));
    };

    const deleteHandler = () => {
        if (post && post?._id) {
            if (typeval == 'post') {
                dispatch(deletePost({ customFetch, id: post?._id }));
                navigate(-1);
            } else {
                dispatch(deleteNews({ customFetch, id: post?._id }));
                navigate(-1);
            }

            if (singlepost) {
                navigate(-1);
            }
        } else {
            console.error('Invalid post data. Unable to delete.');
        }
    };

    const adminPostDeleteHandler = () => {
        dispatch(adminPostDeleteHandler({ customFetch, id: post?._id }));
    }

    const deleteDecider = () => {
        return isAdmin ? adminPostDeleteHandler : deleteHandler;
    }
    const deleteDeciderNews = () => {
        return isAdmin ? adminPostDeleteHandler : deleteHandler;
    }

    const { Confirmation, toggleShow } = useConfirmation(
        deleteDecider(),
        post?.type !== 'news' ? "Are you sure, You want to delete the post?" : "Are you sure, You want to delete the news?"
    );


    const banHandler = () => {
        dispatch(setToBan({ type: "post", id: post?._id }));
    };


    const { toggleShow: showDeleteRepostConfirmation, Confirmation: deleteRepostConfirmation } =
        useConfirmation(repostHandler, "Are you sure, you want to delete the repost");

    const { toggleShow: toggleShowNews, Confirmation: deleteNewsConfirmation } =
        useConfirmation(deleteDeciderNews, "Are you sure, You want to delete the news?");
    console.log(showDeleteRepostConfirmation, 'showDeleteRepostConfirmation')

    const options = {
        Delete: toggleShow,
        Edit: editHandler,
        Report: reportHandler,
        Ban: banHandler,
        Remove: toggleShow,
    };

    const getParagraphs = (text) => {
        const paragraphArray = text.split(/[\n\r]/g);
        return paragraphArray.map((para, i) => {
            if (!para) return null;
            const parts = para.split(' ');

            return (
                <p className={post?.type == 'news' ? "detail_post__caption news" : "detail_post__caption post"} key={i}>
                    {
                        parts.map((part, index) => {
                            return <TextStrategy text={part} index={index} users={users} key={index}></TextStrategy>
                        })
                    }
                </p >
            );
        });
    };
    const OpenCreateNews = () => {
        onOpen();
    };

    const closeFunction = () => {
        onClose();
    };

    const getNumberOf = (array) => {
        if (array?.length) {
            return array?.includes(id)
                ? array?.length - 1 === 0
                    ? "You"
                    : array?.length - 1 === 1
                        ? "You and 1 more"
                        : `You and ${array.length - 1} others`
                : array?.length;
        }
        return "";
    };

    const getRepostIconColor = () =>
        post?.repostedBy?.includes(id) ? "rgb(130,238,149)" : webTheme ? "white" : "black";

    const postDetails = () => {
        const toggleImage = (index) => {
            setShowImage(!showImage);
            if (index !== undefined) {
                const images = post?.images?.map(image => image.src) ?? [];
                setViewData({ images, index });
            } else {
                setViewData(null);
            }
        };

        // Function to render YouTube link
        const renderYoutubeLink = () => {
            if (post?.youtubeLink) {
                const embedUrl = post?.youtubeLink.replace("watch?v=", "embed/");
                return (
                    <div className="youtube-embed">
                        <iframe
                            src={embedUrl}
                            frameBorder="0"
                            allowFullScreen
                            title="YouTube video"
                        ></iframe>
                    </div>
                );
            }
            return null;
        };


        return (
            <>
                {post?.caption && getParagraphs(post?.caption)}
                {(viewData && showImage) && (
                    <Backdrop show={showImage} onClose={toggleImage}>
                        <ImageViewer data={viewData} />
                    </Backdrop>
                )}
                {/* <div className={!!post?.images?.length ? IMAGE_GRID_CLASSES[post?.images.length] : ""}> */}
                <div className="image_class one__images" >
                    {/* {!!postsdata.length && postsdata.map((image, index) => */}

                    {!!post?.images?.length && post?.images.map((image, index) =>

                        <img
                            key={index}
                            src={image.src}
                            alt="post_image"
                            className="detail_post__image"
                            loading="lazy"
                            onClick={() => toggleImage(index)}
                        />
                    )}
                </div>

                {renderYoutubeLink()}
            </>
        );
    };
    // Hide the entire post if the username contains "soibook" or "soIbook"
    if (post?.userDetails?.name && (post?.userDetails.name.toLowerCase().includes("soibook") ||
        post?.userDetails.name.toLowerCase().includes("soIbook"))) {
        return null;
    }
    const {

        search: { webTheme },
    } = useSelector((state) => state);
    const themeClass = webTheme ? "detail_post_dark darkscroll" : "detail_post_light darkscroll";

    // Determine the post classes based on singlepost
    const borderClass = singlepost ? "halfborder" : "gradient-border";
    const postClass = singlepost ? "single" : "";

    // Combine the theme class with other post classes
    const className = `${themeClass} ${borderClass} ${postClass}`.trim();
    useEffect(() => {
        const fetchData = async () => {
            if (typeval == 'post') {
                try {
                    const data = await customFetch(fetchPostsService, { id: index, type: 'single' });
                    if (data) dispatch(setSinglePost(data?.post));
                    if (data) {
                        setPost(data?.post)
                    }
                } catch (error) {
                    console.error('Error fetching post:', error);
                }
            } else if (typeval == 'news') {
                try {
                    const data = await customFetch(fetchNewsService, { id: index, type: 'single' });
                    if (data) dispatch(setSinglePost(data?.post));
                    if (data) {
                        setPost(data?.post)
                    }
                } catch (error) {
                    console.error('Error fetching post:', error);
                }
            }
        };

        fetchData();
    }, [index, dispatch, customFetch]);
    // console.log(post, 'datadatadatadatadatadata')

    return (
        <article className={className}  >
            {Confirmation}
            {deleteRepostConfirmation}
            {deleteNewsConfirmation}
            {console.log(deleteNewsConfirmation, 'deleteNewsConfirmation')}
            <Backdrop show={showShare} onClose={() => setShowShare(false)}>
                <Share post={post} />
            </Backdrop>
            <div className="scroll_div">
                <header>
                    <Link
                        to={`/user/${post?.createdBy}`}
                        className={isOnline ? "green" : ""}
                    >
                        <img
                            src={post?.userDetails?.image || dp}
                            alt="profileImage"
                            loading="lazy"
                            className="detail_post__dp detail_roundimage"
                        />
                    </Link>
                    <div>
                        <h3>
                            {post?.userDetails?.name || "User"}
                            {(verifiedUsers.includes(post?.userDetails?.name) || author && author.verified) && (
                                <span
                                    style={{
                                        display: "inline-flex",
                                        verticalAlign: "middle",
                                        marginLeft: "5px",
                                    }}
                                >
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Eo_circle_light-blue_checkmark.svg/2048px-Eo_circle_light-blue_checkmark.svg.png"
                                        alt="Verified"
                                        style={{ width: "10px", paddingBottom: "3px" }} // Adjusted size
                                    />
                                </span>
                            )}
                            {/* Make the formatted wallet address clickable and direct to solscan.io */}
                            {post?.userDetails?.walletAddress && (
                                <a
                                    href={`https://solscan.io/account/${post?.userDetails?.walletAddress}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: webTheme ? "#8fd7ec" : "#39a0be",
                                        fontSize: "small",
                                        marginLeft: "5px",
                                    }}
                                >
                                    {/* {"useUserDomain(post?.userDetails?.walletAddress)"} */}
                                </a>
                            )}
                        </h3>

                        <p>{createdAt}</p>
                    </div>
                    <Options options={options} isOwnPost={isOwnPost} id={post?._id} targetUser={author} isAdmin={isAdmin} />
                </header>

                <div className="post__details">{postDetails()}</div></div>
            {/* 
            {post?.type !== 'news' && */}
            <div className={webTheme ? "post__footer_dark detail_post_footer" : "post__footer_light detail_post_footer"}>
                <div className="group">
                    <div onClick={isReposted ? showDeleteRepostConfirmation : repostHandler}>
                        <AiOutlineRetweet color={getRepostIconColor()} />
                    </div>
                    <p>{getNumberOf(post?.repostedBy)}</p>
                    <div onClick={likeHandler}>{isLiked ? <AiFillHeart /> : <AiOutlineHeart />}</div>
                    <p>{getNumberOf(post?.likes)}</p>
                </div>
                {singlepost || <Input placeholder={"Write a comment..."} handler={commentHandler} className="detail_input_css" />}
                <Link className={singlepost ? 'comment__icon group' : 'group'} to={`/post/${typeval && typeval}/${post?._id}`}>
                    <BiCommentDetail />
                    <p>{post?.comments?.length ?? 0}</p>
                </Link>
                <div
                    className="group"
                    onClick={() => setShowShare(true)}
                    title="share"
                >
                    <IoIosShareAlt />
                </div>
            </div>
            {/* } */}

        </article>
    );
};

export default PostDetailPage;
