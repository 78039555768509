import {
  commentPostService,
  createPostService,
  deleteCommentService,
  deletePostService,
  editCommentService,
  fetchPostsService,
  likePostService,
  replyCommentService,
  reportPostService,
  repostPostService,
  updatePostService,
} from '../services/postServices';
import { showModal } from './modalSlice';
import { logout, update } from './userSlice';
import trigger from '../utils/trigger';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  allPosts: { posts: [], page: 0, isLoading: false, type: 'all' },
  userPosts: { posts: [], page: 0 },
  editingPost: {},
  singlePost: {},
  reportingPost: {},
  isLoading: false
};

const handleGuest = (isGuest, dispatch) => {
  if (isGuest) {
    dispatch(showModal({ msg: 'You must be logged in to do this action!!' }));
    return true;
  }
  return false;
};

export const setPosts = createAsyncThunk('post/set', async (props, thunkAPI) => {
  const { customFetch } = props;
  const { rejectWithValue, dispatch } = thunkAPI;
  dispatch(postSlice.actions.setLoading(true));
  const data = await customFetch(fetchPostsService, { type: 'all' });
  if (!data) return rejectWithValue();
  dispatch(postSlice.actions.setAllPosts(data));
  dispatch(postSlice.actions.setLoading(false));
  return;
});

export const addPost = createAsyncThunk("post/add", async (props, thunkAPI) => {
  const { customFetch, formData } = props;
  const { fulfillWithValue, dispatch, rejectWithValue, getState } = thunkAPI;
  const {
    user: { isGuest },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  dispatch(showModal({}));
  const data = await customFetch(createPostService, formData);
  if (!data) return rejectWithValue();
  dispatch(showModal({ msg: "Post created" }));
  return fulfillWithValue(data.post);
});

export const updatePost = createAsyncThunk("post/update", async (props, thunkAPI) => {
  const { customFetch, formData, id } = props;
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const {
    user: { isGuest },
    post: { singlePost },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  dispatch(showModal({}));
  const data = await customFetch(updatePostService, { id, form: formData });
  if (!data) return rejectWithValue();
  dispatch(showModal({ msg: "Post updated" }));
  if (singlePost._id === id) dispatch(postSlice.actions.setSinglePost(data.post));
  dispatch(postSlice.actions.updatePosts(data.post));
});

export const reportPost = createAsyncThunk('post/report', async (props, thunkAPI) => {
  const { customFetch, reason, postId } = props;
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const {
    user: { isGuest },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  dispatch(showModal({}));
  const data = await customFetch(reportPostService, { postId, reason });
  if (!data) return rejectWithValue();
  dispatch(showModal({ msg: 'Report created' }));
});

export const likePost = createAsyncThunk('post/like', async (props, thunkAPI) => {
  const { customFetch, id, isLiked } = props;
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const {
    user: { isGuest, id: userId },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  customFetch(likePostService, { id, add: !isLiked });
  return { id, userId, add: !isLiked };
});

export const repostPost = createAsyncThunk("post/repost", async (props, thunkAPI) => {
  const { customFetch, postId: id, isReposted } = props;
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(postSlice.actions.setLoading(true));
  const {
    user: { isGuest, id: userId },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  const data = await customFetch(repostPostService, { id, add: !isReposted });
  if (!data) return rejectWithValue();
  dispatch(showModal({ msg: !isReposted ? "Reposted" : "Repost removed" }));
  dispatch(postSlice.actions.setLoading(false));
  return { id, userId, add: !isReposted };
})

export const commentPost = createAsyncThunk("post/comment", async (props, thunkAPI) => {
  const { customFetch, id, comment } = props;
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const {
    user: { isGuest },
    post: { singlePost },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  const data = await customFetch(commentPostService, { id, comment });
  if (!data) return rejectWithValue();
  if (singlePost._id === id) dispatch(postSlice.actions.setSinglePost(data.post));
  dispatch(postSlice.actions.updatePosts(data.post));
});

export const deletePost = createAsyncThunk('post/delete', async (props, thunkAPI) => {
  const { customFetch, id, frontOnly } = props;
  const { dispatch, fulfillWithValue, getState, rejectWithValue } = thunkAPI;
  const {
    user: { isGuest },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  if (!frontOnly) {
    await customFetch(deletePostService, { id });
  }
  dispatch(showModal({ msg: 'Post Deleted' }));
  return fulfillWithValue(id);
});

export const deleteComment = createAsyncThunk('post/comment/delete', async (props, thunkAPI) => {
  const { customFetch, postId, commentId, replyId } = props;
  const { dispatch, fulfillWithValue, getState, rejectWithValue } = thunkAPI;
  const {
    user: { isGuest },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  const data = await customFetch(deleteCommentService, { postId, commentId, replyId });
  dispatch(showModal({ msg: 'Comment Deleted' }));
  return fulfillWithValue(data);
});

export const editComment = createAsyncThunk('post/comment/edit', async (props, thunkAPI) => {
  const { customFetch, postId, commentId, comment, replyId } = props;
  const { dispatch, rejectWithValue, getState, fulfillWithValue } = thunkAPI;
  const {
    user: { isGuest },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  const data = await customFetch(editCommentService, { postId, commentId, comment, replyId });
  dispatch(showModal({ msg: 'Comment Edited' }));
  return fulfillWithValue(data);
});

export const replyComment = createAsyncThunk('post/comment/reply', async (props, thunkAPI) => {
  const { customFetch, id, commentId, comment, replyTo } = props;
  const { dispatch, rejectWithValue, getState, fulfillWithValue } = thunkAPI;
  const {
    user: { isGuest },
  } = getState();
  if (handleGuest(isGuest, dispatch)) return rejectWithValue();
  const data = await customFetch(replyCommentService, { id, commentId, comment, replyTo });
  return fulfillWithValue(data);
});

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setAllPosts: (state, action) => {
      const { posts, page } = action.payload;
      state.allPosts = { ...state.allPosts, posts, page };
    },
    setUserPosts: (state, action) => {
      state.userPosts = action.payload;
    },
    updatePosts: (state, action) => {
      state.allPosts.posts = state.allPosts.posts.map((post) => {
        if (post._id === action.payload._id) return action.payload;
        return post;
      });
      state.userPosts.posts = state.userPosts.posts.map((post) => {
        if (post._id === action.payload._id) return action.payload;
        return post;
      });
    },
    setSinglePost: (state, action) => {
      state.singlePost = action.payload;
    },
    setEditingPost: (state, action) => {
      state.editingPost = action.payload;
    },
    setReportingPost: (state, action) => {
      state.reportingPost = action.payload;
    },
    setLoading: (state, action) => {
      state.allPosts.isLoading = action.payload;
    },
    deleteAllUserPosts: (state, action) => {
      state.allPosts.posts = state.allPosts.posts.filter(post => post.createdBy !== action.payload.id);
      state.userPosts.posts = state.userPosts.posts.filter(post => post.createdBy !== action.payload.id);
    },
    setAllPostsType: (state, action) => {
      state.allPosts.type = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(addPost.fulfilled, (state, action) => {
      state.allPosts.posts.pop();
      state.userPosts.posts.pop();
      state.allPosts.posts.unshift(action.payload);
      state.userPosts.posts.unshift(action.payload);
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.allPosts.posts = state.allPosts.posts.filter((post) => post._id !== action.payload);
      state.userPosts.posts = state.userPosts.posts.filter((post) => post._id !== action.payload);
    });
    builder.addCase(likePost.fulfilled, (state, { payload }) => {
      trigger(state, { ...payload, type: 'likes' });
    });
    builder.addCase(repostPost.fulfilled, (state, { payload }) => {
      trigger(state, { ...payload, type: 'repostedBy' });
      const { userPosts } = state;
      const { id, add } = payload;

      if (!add) {
        userPosts.posts = userPosts.posts.filter(post => post._id !== id);
      }
    });
    builder.addCase(update.type, (state, action) => {
      const { name, profileImage, id } = action.payload;
      state.allPosts.posts = state?.allPosts?.posts?.map((post) => {
        if (post.createdBy === id) {
          const updatedName = name //|| post.userDetails.name;
          const updatedImage = profileImage //|| post.userDetails.image;
          return {
            ...post,
            userDetails: { name: updatedName, image: updatedImage },
          };
        }
        return post;
      });
      state.userPosts.posts = state?.userPosts?.posts?.map((post) => {
        if (post.createdBy === id) {
          const updatedName = name //|| post.userDetails.name;
          const updatedImage = profileImage //|| post.userDetails.image;
          return {
            ...post,
            userDetails: { name: updatedName, image: updatedImage },
          };
        }
        return post;
      });
    });
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      const { post } = action.payload;
      state.singlePost = post;
      state.allPosts.posts = state.allPosts.posts.map((_post) => (_post._id === post._id ? post : _post));
    });
    builder.addCase(editComment.fulfilled, (state, action) => {
      const { post } = action.payload;
      state.singlePost = post;
    });
    builder.addCase(replyComment.fulfilled, (state, action) => {
      const { post } = action.payload;
      state.singlePost = post;
    });
    builder.addCase(logout.type, (state, action) => {
      return initialState;
    });
    builder.addCase(setPosts.pending, (state) => {
      state.allPosts.isLoading = true;
    });
  },
});

export const {
  setUserPosts,
  setAllPosts,
  setEditingPost,
  setSinglePost,
  setReportingPost,
  deleteAllUserPosts,
  setAllPostsType,
} = postSlice.actions;

export default postSlice.reducer;
