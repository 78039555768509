import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faBell, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import './drawer.css';  // Import the CSS file
import { useSelector, useDispatch } from 'react-redux';
import darkLogo from '../../assets/onlightlogo.png';
import lightLogo from '../../assets/ondarklogo.png';
import { Link } from 'react-router-dom';
import { setThemeHandle } from '../../features/searchSlice';

const DrawerMenu = ({ isOpen, toggleDrawer }) => {
    const [isDarkTheme, setIsDarkTheme] = useState(() => {
        const storedTheme = localStorage.getItem("theme");
        return storedTheme ? JSON.parse(storedTheme) : true;
    });
    const dispatch = useDispatch();
    useEffect(() => {
        localStorage.setItem("theme", JSON.stringify(isDarkTheme));
    }, [isDarkTheme]);

    useEffect(() => {
        dispatch(setThemeHandle(isDarkTheme));
    }, [isDarkTheme, dispatch]);
    const { search: { webTheme } } = useSelector((state) => state);


    const toggleTheme = () => {
        const newTheme = !isDarkTheme;
        setIsDarkTheme(newTheme);
    };
    const classname = webTheme ? 'dark' : 'light'

    return (
        <div className={classname}>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className={`menu-drawer ${classname}`}
            >
                <nav className='menu'>
                    <ul>
                        <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <img src={webTheme ? lightLogo : darkLogo} alt="logo" style={{ height: '40px', width: '45px' }} />
                            <a onClick={toggleDrawer}>Close</a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faHome} /> <a href='#home'>Home</a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faUser} /> <a href='#user'>User</a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBell} /> <a href='#notifications'>Notifications</a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={webTheme ? faSun : faMoon} />
                            <a onClick={toggleTheme}>
                                {webTheme ? 'Light Mode' : 'Dark Mode'}
                            </a>
                        </li>
                    </ul>
                </nav>
            </Drawer>
        </div>
    );
};

export default DrawerMenu;
