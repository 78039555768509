import { useSelector } from 'react-redux';
import { sendIcon } from '../../assets';
import React, { useState } from 'react';

const initialForm = { reason: "" };

const Reason = ({ onClose, onSubmit, placeholder }) => {
  const [form, setForm] = useState(initialForm);

  const submitHandler = async (event) => {
    event.preventDefault();
    const { reason } = form;
    setForm(initialForm);
    onClose();
    onSubmit({ reason });
  }
  const {

    search: { webTheme },
  } = useSelector((state) => state);
  return (
    <article className={webTheme ? "createpost_dark gradient-border reportpost_dark" : "createpost_light gradient-border reportpost_light"}>
      <form onSubmit={submitHandler}>
        <textarea
          placeholder={placeholder}
          value={form.reason}
          onChange={e => setForm({ ...form, reason: e.target.value })}
        />

        <div className="btns">
          <button type="submit" aria-label="submit">
            <img src={sendIcon} alt="send" />
          </button>
        </div>
      </form>
    </article>
  );
}

export default Reason;
